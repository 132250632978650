import { createReducer } from 'deox';

import { TPostPublicMemberControllerResponse } from '@/services/api/public-review';
import { postPublicMemberControllerAction } from '@/redux/actions';
import { postPublicMemberControllerUpdateState } from './post-public-member-controller';

export type TPublicReviewState = {
  postPublicMemberControllerResponse?: TPostPublicMemberControllerResponse;
};

const initialState: TPublicReviewState = {
  postPublicMemberControllerResponse: undefined,
};

const PublicReviewReducer = createReducer(initialState, (handleAction) => [
  handleAction(postPublicMemberControllerAction.success, postPublicMemberControllerUpdateState),
]);

export default PublicReviewReducer;
