import { combineReducers } from 'redux';

import { loadingReducer, errorReducer, successReducer } from './status';
import appointmentReducer from './appointment';
import authReducer from './auth';
import categoryReducer from './category';
import memberReducer from './member';
import publicReviewReducer from './public-review';
import settingReducer from './setting';
import shopReducer from './shop';
import technicianReducer from './technician';
import uiReducer from './ui';

const rootReducer = combineReducers({
  loadingReducer,
  errorReducer,
  successReducer,
  appointmentReducer,
  authReducer,
  categoryReducer,
  memberReducer,
  publicReviewReducer,
  settingReducer,
  shopReducer,
  technicianReducer,
  uiReducer,
});

export default rootReducer;

export type TRootState = ReturnType<typeof rootReducer>;
