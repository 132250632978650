import ApiService from '@/services/api';

// TYPES

export type TPostPublicMemberControllerPaths = {
  id: string | number;
};
export type TPostPublicMemberControllerParams = unknown;
export type TPostPublicMemberControllerBody = unknown;

export type TPostPublicMemberControllerMaterials = {
  paths?: TPostPublicMemberControllerPaths;
  params?: TPostPublicMemberControllerParams;
  body?: TPostPublicMemberControllerBody;
};

export type TPostPublicMemberControllerResponse = unknown;

// FUNCTION

export const postPublicMemberController = async ({
  paths,
  params,
  body,
}: TPostPublicMemberControllerMaterials): Promise<TPostPublicMemberControllerResponse> => {
  const response = await ApiService.post(`/api-public/members/${paths?.id}/review`, body, { params });
  return response?.data;
};
