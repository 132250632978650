import { createActionCreator } from 'deox';

import {
  TPostPublicMemberControllerMaterials,
  TPostPublicMemberControllerResponse,
} from '@/services/api/public-review/post-public-member-controller';

// CONSTANTS

export enum EPostPublicMemberControllerAction {
  POST_PUBLIC_MEMBER_CONTROLLER = 'POST_PUBLIC_MEMBER_CONTROLLER',
  POST_PUBLIC_MEMBER_CONTROLLER_REQUEST = 'POST_PUBLIC_MEMBER_CONTROLLER_REQUEST',
  POST_PUBLIC_MEMBER_CONTROLLER_SUCCESS = 'POST_PUBLIC_MEMBER_CONTROLLER_SUCCESS',
  POST_PUBLIC_MEMBER_CONTROLLER_FAILED = 'POST_PUBLIC_MEMBER_CONTROLLER_FAILED',
}

// TYPES

export type TPostPublicMemberControllerRequest = {
  type: EPostPublicMemberControllerAction.POST_PUBLIC_MEMBER_CONTROLLER_REQUEST;
  payload: {
    materials: TPostPublicMemberControllerMaterials;
    successCallback?: (response: TPostPublicMemberControllerResponse) => void;
    failedCallback?: (err: unknown) => void;
  };
};

export type TPostPublicMemberControllerSuccess = {
  type: EPostPublicMemberControllerAction.POST_PUBLIC_MEMBER_CONTROLLER_SUCCESS;
  payload: { response: TPostPublicMemberControllerResponse };
};

export type TPostPublicMemberControllerFailed = {
  type: EPostPublicMemberControllerAction.POST_PUBLIC_MEMBER_CONTROLLER_FAILED;
};

// FUNCTION

export const postPublicMemberControllerAction = {
  request: createActionCreator(
    EPostPublicMemberControllerAction.POST_PUBLIC_MEMBER_CONTROLLER_REQUEST,
    (resolve) =>
      (
        materials: TPostPublicMemberControllerMaterials,
        successCallback?: (response: TPostPublicMemberControllerResponse) => void,
        failedCallback?: (err: unknown) => void,
      ): TPostPublicMemberControllerRequest =>
        resolve({ materials, successCallback, failedCallback }),
  ),
  success: createActionCreator(
    EPostPublicMemberControllerAction.POST_PUBLIC_MEMBER_CONTROLLER_SUCCESS,
    (resolve) =>
      (response: TPostPublicMemberControllerResponse): TPostPublicMemberControllerSuccess =>
        resolve({ response }),
  ),
  failure: createActionCreator(
    EPostPublicMemberControllerAction.POST_PUBLIC_MEMBER_CONTROLLER_FAILED,
    (resolve) =>
      (error: unknown): TPostPublicMemberControllerFailed =>
        resolve({ error }),
  ),
};
