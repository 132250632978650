import { TPublicReviewState } from '@/redux/reducers/public-review';
import { TPostPublicMemberControllerSuccess } from '@/redux/actions/public-review';

export const postPublicMemberControllerUpdateState = (
  state: TPublicReviewState,
  action: TPostPublicMemberControllerSuccess,
): TPublicReviewState => ({
  ...state,
  postPublicMemberControllerResponse: action.payload.response,
});
