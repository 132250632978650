import { ActionType } from 'deox';
import { call, put } from 'redux-saga/effects';

import { postPublicMemberControllerAction } from '@/redux/actions';
import { postPublicMemberController, TPostPublicMemberControllerResponse } from '@/services/api';

// FUNCTION

export function* postPublicMemberControllerSaga(
  action: ActionType<typeof postPublicMemberControllerAction.request>,
): Generator {
  const { materials, successCallback, failedCallback } = action.payload;
  try {
    const response = yield call(postPublicMemberController, materials);
    const postPublicMemberControllerResponse: TPostPublicMemberControllerResponse =
      response as TPostPublicMemberControllerResponse;
    yield put(postPublicMemberControllerAction.success(postPublicMemberControllerResponse));
    successCallback?.(postPublicMemberControllerResponse);
  } catch (err) {
    yield put(postPublicMemberControllerAction.failure(err));
    failedCallback?.(err);
  }
}
